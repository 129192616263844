.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-wrapper-content {
  padding-left: 330px;
  position: relative;
  transition: all 0.5s ease-out;
}
.main-wrapper-content.active {
  padding-left: 0;
}

.default-2 {
  padding-left: 0px;
}

.text-white {
  color: #ffffff !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-danger {
  color: #ff0000 !important;
}

.error-message {
  color: #ff0000 !important;
  font-size: 13.5px !important;
}

.pull-right,
.pull-right div {
  float: right !important;
}

.css-o67iqg {
  top: -5px !important;
}
