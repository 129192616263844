[dir=rtl] table, [dir=rtl] th, [dir=rtl] tr, [dir=rtl] td {
  text-align: right;
}
[dir=rtl] .float-left {
  float: initial;
  float: right;
}
[dir=rtl] .float-right {
  float: initial;
  float: left;
}
[dir=rtl] .ml-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}
[dir=rtl] .ml-1 {
  margin-left: 0 !important;
  margin-right: 8px !important;
}
[dir=rtl] .ml-2 {
  margin-left: 0 !important;
  margin-right: 16px !important;
}
[dir=rtl] .ml-3 {
  margin-left: 0 !important;
  margin-right: 24px !important;
}
[dir=rtl] .ml-4 {
  margin-left: 0 !important;
  margin-right: 32px !important;
}
[dir=rtl] .ml-5 {
  margin-left: 0 !important;
  margin-right: 40px !important;
}
[dir=rtl] .mr-1 {
  margin-right: 0 !important;
  margin-left: 8px !important;
}
[dir=rtl] .mr-2 {
  margin-right: 0 !important;
  margin-left: 16px !important;
}
[dir=rtl] .mr-3 {
  margin-right: 0 !important;
  margin-left: 24px !important;
}
[dir=rtl] .mr-4 {
  margin-right: 0 !important;
  margin-left: 32px !important;
}
[dir=rtl] .mr-5 {
  margin-right: 0 !important;
  margin-left: 40px !important;
}
[dir=rtl] .ml-5px {
  margin-left: 0 !important;
  margin-right: 5px !important;
}
[dir=rtl] .ml-6px {
  margin-left: 0 !important;
  margin-right: 6px !important;
}
[dir=rtl] .ml-7px {
  margin-left: 0 !important;
  margin-right: 7px !important;
}
[dir=rtl] .ml-8px {
  margin-left: 0 !important;
  margin-right: 8px !important;
}
[dir=rtl] .ml-9px {
  margin-left: 0 !important;
  margin-right: 9px !important;
}
[dir=rtl] .ml-10px {
  margin-left: 0 !important;
  margin-right: 10px !important;
}
[dir=rtl] .ml-11px {
  margin-left: 0 !important;
  margin-right: 11px !important;
}
[dir=rtl] .ml-12px {
  margin-left: 0 !important;
  margin-right: 12px !important;
}
[dir=rtl] .ml-13px {
  margin-left: 0 !important;
  margin-right: 13px !important;
}
[dir=rtl] .ml-14px {
  margin-left: 0 !important;
  margin-right: 14px !important;
}
[dir=rtl] .ml-15px {
  margin-left: 0 !important;
  margin-right: 15px !important;
}
[dir=rtl] .ml-16px {
  margin-left: 0 !important;
  margin-right: 16px !important;
}
[dir=rtl] .ml-17px {
  margin-left: 0 !important;
  margin-right: 17px !important;
}
[dir=rtl] .ml-18px {
  margin-left: 0 !important;
  margin-right: 18px !important;
}
[dir=rtl] .ml-19px {
  margin-left: 0 !important;
  margin-right: 19px !important;
}
[dir=rtl] .ml-20px {
  margin-left: 0 !important;
  margin-right: 20px !important;
}
[dir=rtl] .ml-21px {
  margin-left: 0 !important;
  margin-right: 21px !important;
}
[dir=rtl] .ml-22px {
  margin-left: 0 !important;
  margin-right: 22px !important;
}
[dir=rtl] .ml-23px {
  margin-left: 0 !important;
  margin-right: 23px !important;
}
[dir=rtl] .ml-24px {
  margin-left: 0 !important;
  margin-right: 24px !important;
}
[dir=rtl] .ml-25px {
  margin-left: 0 !important;
  margin-right: 25px !important;
}
[dir=rtl] .ml-26px {
  margin-left: 0 !important;
  margin-right: 26px !important;
}
[dir=rtl] .ml-27px {
  margin-left: 0 !important;
  margin-right: 27px !important;
}
[dir=rtl] .ml-28px {
  margin-left: 0 !important;
  margin-right: 28px !important;
}
[dir=rtl] .ml-29px {
  margin-left: 0 !important;
  margin-right: 29px !important;
}
[dir=rtl] .ml-30px {
  margin-left: 0 !important;
  margin-right: 30px !important;
}
[dir=rtl] .mr-5px {
  margin-right: 0 !important;
  margin-left: 5px !important;
}
[dir=rtl] .mr-6px {
  margin-right: 0 !important;
  margin-left: 6px !important;
}
[dir=rtl] .mr-7px {
  margin-right: 0 !important;
  margin-left: 7px !important;
}
[dir=rtl] .ml-8px {
  margin-right: 0 !important;
  margin-left: 8px !important;
}
[dir=rtl] .mr-9px {
  margin-right: 0 !important;
  margin-left: 9px !important;
}
[dir=rtl] .mr-10px {
  margin-right: 0 !important;
  margin-left: 10px !important;
}
[dir=rtl] .mr-11px {
  margin-right: 0 !important;
  margin-left: 11px !important;
}
[dir=rtl] .mr-12px {
  margin-right: 0 !important;
  margin-left: 12px !important;
}
[dir=rtl] .mr-13px {
  margin-right: 0 !important;
  margin-left: 13px !important;
}
[dir=rtl] .mr-14px {
  margin-right: 0 !important;
  margin-left: 14px !important;
}
[dir=rtl] .mr-15px {
  margin-right: 0 !important;
  margin-left: 15px !important;
}
[dir=rtl] .mr-16px {
  margin-right: 0 !important;
  margin-left: 16px !important;
}
[dir=rtl] .mr-17px {
  margin-right: 0 !important;
  margin-left: 17px !important;
}
[dir=rtl] .mr-18px {
  margin-right: 0 !important;
  margin-left: 18px !important;
}
[dir=rtl] .mr-19px {
  margin-right: 0 !important;
  margin-left: 19px !important;
}
[dir=rtl] .mr-20px {
  margin-right: 0 !important;
  margin-left: 20px !important;
}
[dir=rtl] .mr-21px {
  margin-right: 0 !important;
  margin-left: 21px !important;
}
[dir=rtl] .mr-22px {
  margin-right: 0 !important;
  margin-left: 22px !important;
}
[dir=rtl] .mr-23px {
  margin-right: 0 !important;
  margin-left: 23px !important;
}
[dir=rtl] .mr-24px {
  margin-right: 0 !important;
  margin-left: 24px !important;
}
[dir=rtl] .mr-25px {
  margin-right: 0 !important;
  margin-left: 25px !important;
}
[dir=rtl] .mr-26px {
  margin-right: 0 !important;
  margin-left: 26px !important;
}
[dir=rtl] .mr-27px {
  margin-right: 0 !important;
  margin-left: 27px !important;
}
[dir=rtl] .mr-28px {
  margin-right: 0 !important;
  margin-left: 28px !important;
}
[dir=rtl] .mr-29px {
  margin-right: 0 !important;
  margin-left: 29px !important;
}
[dir=rtl] .mr-30px {
  margin-right: 0 !important;
  margin-left: 30px !important;
}
[dir=rtl] .pr-5px {
  padding-right: 0;
  padding-left: 5px;
}
[dir=rtl] .pr-6px {
  padding-right: 0;
  padding-left: 6px;
}
[dir=rtl] .pr-7px {
  padding-right: 0;
  padding-left: 7px;
}
[dir=rtl] .pr-8px {
  padding-right: 0;
  padding-left: 8px;
}
[dir=rtl] .pr-9px {
  padding-right: 0;
  padding-left: 9px;
}
[dir=rtl] .pr-10px {
  padding-right: 0;
  padding-left: 10px;
}
[dir=rtl] .pr-11px {
  padding-right: 0;
  padding-left: 11px;
}
[dir=rtl] .pr-12px {
  padding-right: 0;
  padding-left: 12px;
}
[dir=rtl] .pr-13px {
  padding-right: 0;
  padding-left: 13px;
}
[dir=rtl] .pr-14px {
  padding-right: 0;
  padding-left: 14px;
}
[dir=rtl] .pr-15px {
  padding-right: 0;
  padding-left: 15px;
}
[dir=rtl] .pr-16px {
  padding-right: 0;
  padding-left: 16px;
}
[dir=rtl] .pr-17px {
  padding-right: 0;
  padding-left: 17px;
}
[dir=rtl] .pr-18px {
  padding-right: 0;
  padding-left: 18px;
}
[dir=rtl] .pr-19px {
  padding-right: 0;
  padding-left: 19px;
}
[dir=rtl] .pr-20px {
  padding-right: 0;
  padding-left: 20px;
}
[dir=rtl] .pr-21px {
  padding-right: 0;
  padding-left: 21px;
}
[dir=rtl] .pr-22px {
  padding-right: 0;
  padding-left: 22px;
}
[dir=rtl] .pr-23px {
  padding-right: 0;
  padding-left: 23px;
}
[dir=rtl] .pr-24px {
  padding-right: 0;
  padding-left: 24px;
}
[dir=rtl] .pr-25px {
  padding-right: 0;
  padding-left: 25px;
}
[dir=rtl] .pr-26px {
  padding-right: 0;
  padding-left: 26px;
}
[dir=rtl] .pr-27px {
  padding-right: 0;
  padding-left: 27px;
}
[dir=rtl] .pr-28px {
  padding-right: 0;
  padding-left: 28px;
}
[dir=rtl] .pr-29px {
  padding-right: 0;
  padding-left: 29px;
}
[dir=rtl] .pr-30px {
  padding-right: 0;
  padding-left: 30px;
}
[dir=rtl] .pr-35px {
  padding-right: 0;
  padding-left: 35px;
}
[dir=rtl] .pr-40px {
  padding-right: 0;
  padding-left: 40px;
}
[dir=rtl] .pr-45px {
  padding-right: 0;
  padding-left: 45px;
}
[dir=rtl] .pr-50px {
  padding-right: 0;
  padding-left: 50px;
}
[dir=rtl] .pr-55px {
  padding-right: 0;
  padding-left: 55px;
}
[dir=rtl] .pr-60px {
  padding-right: 0;
  padding-left: 60px;
}
[dir=rtl] .pl-5px {
  padding-left: 0;
  padding-right: 5px;
}
[dir=rtl] .pl-6px {
  padding-left: 0;
  padding-right: 6px;
}
[dir=rtl] .pl-7px {
  padding-left: 0;
  padding-right: 7px;
}
[dir=rtl] .pl-8px {
  padding-left: 0;
  padding-right: 8px;
}
[dir=rtl] .pl-9px {
  padding-left: 0;
  padding-right: 9px;
}
[dir=rtl] .pl-10px {
  padding-left: 0;
  padding-right: 10px;
}
[dir=rtl] .pl-11px {
  padding-left: 0;
  padding-right: 11px;
}
[dir=rtl] .pl-12px {
  padding-left: 0;
  padding-right: 12px;
}
[dir=rtl] .pl-13px {
  padding-left: 0;
  padding-right: 13px;
}
[dir=rtl] .pl-14px {
  padding-left: 0;
  padding-right: 14px;
}
[dir=rtl] .pl-15px {
  padding-left: 0;
  padding-right: 15px;
}
[dir=rtl] .pl-16px {
  padding-left: 0;
  padding-right: 16px;
}
[dir=rtl] .pl-17px {
  padding-left: 0;
  padding-right: 17px;
}
[dir=rtl] .pl-18px {
  padding-left: 0;
  padding-right: 18px;
}
[dir=rtl] .pl-19px {
  padding-left: 0;
  padding-right: 19px;
}
[dir=rtl] .pl-20px {
  padding-left: 0;
  padding-right: 20px;
}
[dir=rtl] .pl-21px {
  padding-left: 0;
  padding-right: 21px;
}
[dir=rtl] .pl-22px {
  padding-left: 0;
  padding-right: 22px;
}
[dir=rtl] .pl-23px {
  padding-left: 0;
  padding-right: 23px;
}
[dir=rtl] .pl-24px {
  padding-left: 0;
  padding-right: 24px;
}
[dir=rtl] .pl-25px {
  padding-left: 0;
  padding-right: 25px;
}
[dir=rtl] .pl-26px {
  padding-left: 0;
  padding-right: 26px;
}
[dir=rtl] .pl-27px {
  padding-left: 0;
  padding-right: 27px;
}
[dir=rtl] .pl-28px {
  padding-left: 0;
  padding-right: 28px;
}
[dir=rtl] .pl-29px {
  padding-left: 0;
  padding-right: 29px;
}
[dir=rtl] .pl-30px {
  padding-left: 0;
  padding-right: 30px;
}
[dir=rtl] .pl-35px {
  padding-left: 0;
  padding-right: 35px;
}
[dir=rtl] .pl-40px {
  padding-left: 0;
  padding-right: 40px;
}
[dir=rtl] .pl-45px {
  padding-left: 0;
  padding-right: 45px;
}
[dir=rtl] .pl-50px {
  padding-left: 0;
  padding-right: 50px;
}
[dir=rtl] .pl-55px {
  padding-left: 0;
  padding-right: 55px;
}
[dir=rtl] .pl-60px {
  padding-left: 0;
  padding-right: 60px;
}
[dir=rtl] .right-5px {
  right: auto;
  left: 5px;
}
[dir=rtl] .right-6px {
  right: auto;
  left: 6px;
}
[dir=rtl] .right-7px {
  right: auto;
  left: 7px;
}
[dir=rtl] .right-8px {
  right: auto;
  left: 8px;
}
[dir=rtl] .right-9px {
  right: auto;
  left: 9px;
}
[dir=rtl] .right-10px {
  right: auto;
  left: 10px;
}
[dir=rtl] .right-11px {
  right: auto;
  left: 11px;
}
[dir=rtl] .right-12px {
  right: auto;
  left: 12px;
}
[dir=rtl] .right-13px {
  right: auto;
  left: 13px;
}
[dir=rtl] .right-14px {
  right: auto;
  left: 14px;
}
[dir=rtl] .right-15px {
  right: auto;
  left: 15px;
}
[dir=rtl] .right-16px {
  right: auto;
  left: 16px;
}
[dir=rtl] .right-17px {
  right: auto;
  left: 17px;
}
[dir=rtl] .right-18px {
  right: auto;
  left: 18px;
}
[dir=rtl] .right-19px {
  right: auto;
  left: 19px;
}
[dir=rtl] .right-20px {
  right: auto;
  left: 20px;
}
[dir=rtl] .right-21px {
  right: auto;
  left: 21px;
}
[dir=rtl] .right-22px {
  right: auto;
  left: 22px;
}
[dir=rtl] .right-23px {
  right: auto;
  left: 23px;
}
[dir=rtl] .right-24px {
  right: auto;
  left: 24px;
}
[dir=rtl] .right-25px {
  right: auto;
  left: 25px;
}
[dir=rtl] .right-26px {
  right: auto;
  left: 27px;
}
[dir=rtl] .right-28px {
  right: auto;
  left: 28px;
}
[dir=rtl] .right-29px {
  right: auto;
  left: 29px;
}
[dir=rtl] .right-30px {
  right: auto;
  left: 30px;
}
[dir=rtl] .search-form {
  margin-right: 20px;
  margin-left: 0;
}
[dir=rtl] .LeftSidebarNav {
  left: auto;
  right: 0;
}
[dir=rtl] .main-wrapper-content {
  padding-left: 0;
  padding-right: 330px;
}
[dir=rtl] .main-wrapper-content .main-content {
  padding-right: 0;
  padding-left: 30px;
}
[dir=rtl] .main-wrapper-content.active {
  padding-right: 30px;
}
[dir=rtl] .main-wrapper-content.active .LeftSidebarNav {
  left: auto;
  right: -100%;
}
@media only screen and (max-width: 767px) {
  [dir=rtl] .main-wrapper-content .main-content {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 1199px) {
  [dir=rtl] .main-wrapper-content {
    padding: 0;
  }
  [dir=rtl] .main-wrapper-content .LeftSidebarNav {
    left: auto;
    right: -100%;
    width: 280px;
  }
  [dir=rtl] .main-wrapper-content .main-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  [dir=rtl] .main-wrapper-content.active {
    padding: 0;
  }
  [dir=rtl] .main-wrapper-content.active .LeftSidebarNav {
    left: auto;
    right: 0;
  }
}
[dir=rtl] .apexcharts-legend-marker {
  margin-right: 0;
  margin-left: 3px;
}
[dir=rtl] .apexcharts-yaxis, [dir=rtl] .apexcharts-subtitle-text, [dir=rtl] .apexcharts-title-text {
  direction: ltr;
}
[dir=rtl] .css-1qlw4ct-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before, [dir=rtl] .css-1mpld98-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  display: none;
}
[dir=rtl] .recharts-legend-item .recharts-surface {
  margin-right: 0;
  margin-left: 4px;
}
[dir=rtl] .ls-search-form input.MuiInputBase-input {
  padding-right: 15px;
}
[dir=rtl] .ls-search-form .search-btn {
  right: auto;
  left: 0;
}
[dir=rtl] .css-s2uf1z {
  text-align: left;
}
[dir=rtl] .MuiAlert-icon {
  margin-right: 0;
  margin-left: 12px;
}
[dir=rtl] .input-label label {
  left: auto;
  right: 0;
  transform: translate(-15px, 16px) scale(1);
}
[dir=rtl] .css-b1hphm-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  margin-right: 0;
  margin-left: 10px;
}
[dir=rtl] .avatars-gap .MuiAvatar-root {
  margin-left: 16px;
}
[dir=rtl] .avatars-gap .MuiAvatar-root:last-child {
  margin-left: 0;
}
[dir=rtl] .gadge-gap .MuiBadge-root {
  margin-left: 16px;
}
[dir=rtl] .gadge-gap .MuiBadge-root:last-child {
  margin-left: 0;
}
[dir=rtl] .MuiButtonBase-root .MuiButton-startIcon {
  margin-right: 0;
  margin-left: 8px;
}
[dir=rtl] .MuiAlert-root .css-ki1hdl-MuiAlert-action {
  margin-left: -8px;
  margin-right: auto;
}
[dir=rtl] .chip-gap-for-rtl .MuiChip-root {
  margin-left: 0;
  margin-right: 15px;
}
[dir=rtl] .chip-gap-for-rtl .MuiChip-root:first-child {
  margin-right: 0;
}
[dir=rtl] .direction-ltr {
  direction: ltr;
}
[dir=rtl] .rtl-step-label .MuiStepLabel-iconContainer {
  padding-right: 0;
  padding-left: 10px;
}
[dir=rtl] .scroll-to-top i {
  right: auto;
  left: 20px;
}
[dir=rtl] .control-panel-modal {
  right: auto;
  left: -100%;
}
[dir=rtl] .control-panel-modal.show {
  right: auto;
  left: 0;
}
[dir=rtl] .control-panel-modal .settings-btn {
  right: auto;
  left: 15px;
}
[dir=rtl] .control-panel-dialog {
  margin-left: 0;
  margin-right: auto;
}/*# sourceMappingURL=rtl.css.map */